import React from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

import RDMD, { TOC } from '@ui/RDMD';

interface DocBodyProps {
  dehydrated?: {
    body: string;
    toc: string;
  };
  docBody: string;
  footer: React.ReactNode;
  hideTOC: boolean;
  opts: Record<string, unknown>;
}

const DocBody = ({ hideTOC, dehydrated, docBody, opts = {}, footer }: DocBodyProps) => {
  const { isClient } = useEnvInfo();

  return (
    <div className="grid-container-fluid" id="content-container">
      <section className={`content-body grid-${hideTOC ? 100 : 75}`}>
        <RDMD
          key={isClient ? window.location.pathname : null}
          body={docBody}
          className="rm-Markdown markdown-body ng-non-bindable"
          dehydrated={dehydrated?.body}
          opts={opts}
          style={{ marginLeft: 31 }}
        />
        {!!footer && footer}
      </section>
      {!hideTOC && (
        <section className="content-toc grid-25">
          <TOC body={docBody} opts={opts} />
        </section>
      )}
    </div>
  );
};

export default DocBody;
